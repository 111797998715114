import LayoutB1 from "../../components/layout-b1"
import React from "react"
import "./b1_profile_login.css"
import { Modal} from 'antd'
import MyNavLink from '../../components/my-nav-link'
import { navigate } from "@reach/router"
import PubSub from "pubsub-js"

export default class B1ProfileLogin extends React.Component {
  state={
    codeMatch:false,
    getCodeChange:true,
    count:60
  }



  phoneInput=(e)=>{
    console.log('phone:',e.target.value)
    if (isNaN(e.target.value)) {
      console.log("只能输入数字")
      return
    }
    let phone=parseInt(e.target.value,10)
    this.setState({
      phone:phone,
    })
  }

  getCode=()=>{
    console.log('phone:',this.state.phone)
    if(!this.state.phone) {
      Modal.warning({
        title: '请输入手机号',
      })
      return false
    }
    let count = this.state.count
    const timer = setInterval(() => {
      this.setState({
        getCodeChange:false,
        count: (count--)
      }, () => {
        if (count === 0) {
          clearInterval(timer);
          this.setState({
            getCodeChange: true,
            count: 60
          })
        }
      })
    }, 1000)


  }

  codeInput=(e)=>{
    console.log('code:',e.target.value)
    let code=parseInt(e.target.value,10)
    this.setState({
      code:code,
      codeMatch:code===123456?true:false
    })
  }

  login=()=>{
    console.log("code:",this.state.code)
    if(!this.state.phone) {
      Modal.warning({
        title: '请输入手机号',
      })
      return false
    }
    if(!this.state.code) {
      Modal.warning({
        title: '请输入验证码',
      })
      return false
    }
    if(!this.state.codeMatch) {
      Modal.warning({
        title: '验证码错误',
      })
      return false
    }
    navigate('/demos/themeB1/profile/enroll_list')
    PubSub.publish('login')

  }

  render() {
    const color='#fc9816'
    return(
      <LayoutB1 location={this.props.location} >
        <div className="profile-body">
          <div className="profile-container">
            <div className="login-container">
              <div className="login-title">登录</div>
              <div className="phone-input">
                <img alt="手机号" className="phone-icon" src={require('../../images/b1/shouji.svg')}/>
                <input placeholder='请输入中国大陆11位手机号' value={this.state.phone||''} maxLength={11} pattern="[0-9]" onChange={this.phoneInput}/>
              </div>
              <div className="code-input">
                <img alt="验证码" className="code-icon" src={require('../../images/b1/shouji.svg')}/>
                <input placeholder='请输入短信验证码'  ref='code' value={this.state.code||''} onChange={this.codeInput}/>
                <button onClick={this.getCode} className="get-code-btn" disabled={this.state.getCodeChange ? false : true} style={{color:this.state.getCodeChange ?'black':'#999999',background:this.state.getCodeChange ?color:'#dcdcdc',outline:'none'}}>
                  <div className='get-code-text'>{this.state.getCodeChange ? '发送验证码':this.state.count+'秒后重发'}</div>
                </button>
              </div>
              <div onClick={this.login} className="login-btn"  style={{color:color,background:'black'}}>
                <div className="login-btn-text" style={{color:color}}>登录</div>
              </div>
            </div>
          </div>
        </div>
      </LayoutB1>
    )
  }
}